import { InferType } from "yup";
import { cloudRenderingPoliciesSchema, sessionSchema } from "./schema";

export namespace SessionManagementApi {
  export type DeviceType =
    | "PcDesktopClient"
    | "VrStandalone"
    | "Unknown"
    | "PcRemoteClient"
    | "Browser";

  export type TargetPlatform = "Windows" | "Android";

  export type SessionResponse = {
    id: string;
    userIdentifier: string;
    appId: string;
    appTargetPlatform: TargetPlatform;
    deviceIdentifier: string;
    deviceType: DeviceType;
    organizationId: string;
    experienceStartedDateTime: string | null;
    sessionTerminatedDateTime: string | null;
    renderingRegion: string | null;
  };
}

export type PaginatedList<TType> = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: TType[];
  pageNumber: number;
  totalPages: number;
  totalResults: number;
};

export type Session = InferType<typeof sessionSchema>;

export type SessionList = PaginatedList<Session>;

export type Region = {
  isEnabled: boolean;
  name: string;
  timeZoneId: string;
  displayName: string;
  cloudProvider: CloudProvider;
  type: "WavelengthZone" | "LocalZone" | "Region" | "Unmanaged";
  networkOperator: string;
  supportedVmSizes: string[];
  distanceM: number;
  latitude: number;
  longitude: number;
  latencyTestUrl: string;
};

export type UnmanagedNode = {
  id: string;
  identifier: string | null;
  regionName: string | null;
  vmSize: string | null;
  dateTimeLastReady: string | null;
  ipAddress: string | null;
  activeUser: string | null;
  isDisabled: boolean;
  isReady: boolean;
  isInUse: boolean;
  requiresReset: boolean;
};

export const knownReservedInstanceType = ["Warm", "Hot"] as const;
export type ReservedInstanceType = (typeof knownReservedInstanceType)[number];

export interface TimeZone {
  id: string;
  displayName: string;
  baseUtcOffset: string;
}

export interface FixedIp {
  id: string;
  region: string;
  organizationId: string;
  ip?: string;
}

export interface ReservedInstance {
  id: string;
  timeZoneId: string;
  organizationId: string | null;
  userId: string;
  userIdentifier: string;
  region: string;
  regionDisplayName: string;
  instances: number;
  type: ReservedInstanceType;
  vmSize: string;
  vmSizeDisplayName: string;
  weekDaysBitmask: number | null;
  fromTime: string | null;
  toTime: string | null;
  fromDate: string | null;
  toDate: string | null;
  description: string | null;
  isCurrentlyActive: boolean;
  sourceKey?: string;
}

export interface VmSizeInformation {
  name: string;
  displayName: string;
  graphicsCardDisplayName: string;
  isDefault: boolean;
  minRam: number;
  minVCpu: number;
  regionalAvailability: string[];
  isEnabled: boolean;
}

export type UpdateReservedInstance = Omit<
  ReservedInstance,
  | "id"
  | "userId"
  | "userIdentifier"
  | "isCurrentlyActive"
  | "description"
  | "regionDisplayName"
  | "vmSizeDisplayName"
> & { description: string }; // we always want to set a description

export const WeekDay = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export interface SessionFilters {
  page?: number;
  pageSize?: number;
  from?: Date;
  to?: Date;
  deviceType?: "VrStandalone" | "Browser" | "PcDesktopClient";
  targetPlatform?: SessionManagementApi.TargetPlatform;
  renderType?: "LocalRendered" | "CloudRendered";
  userIdentifier?: (string | number)[];
  organizationId?: string | number;
  appId?: string | number;
}

export type CloudProvider = "AWS" | "Azure" | "GoogleCloud" | "Unmanaged";

export interface RegionFilter {
  organizationId?: string | null;
  appId?: string;
  deviceIdentifier?: string;
  latencyInfo?: { [key: string]: number };
  preferredVmSize?: string;
  preferredRegion?: string;
  allowedCloudProviders?: CloudProvider[];
  allowedNetworkOperators?: string[];
  allowLocalZones?: boolean;
  allowWavelengthZones?: boolean;
  allowedWavelengthZones?: string[];
  includeDisabledRegions?: boolean;
  includeEmptyVmSizes?: boolean;
}

export type SessionsStatDto = {
  key: string;
  count: number;
  totalDurationSeconds: number;
  averageDurationSeconds: number;
};

export type SessionsConcurrencyStat = {
  maxConcurrentSessions: number;
};

export type SessionsStatsDto = {
  sessionsStats: SessionsStatDto[];
};

export type SessionStatsGroupByFilter =
  | "year"
  | "month"
  | "day"
  | "application"
  | "applicationBuild"
  | "user";

export interface VirtualMachine {
  id: string;
  region: string;
  regionDisplayName: string;
  vmSize: string;
  vmSizeDisplayName: string;
  activeUserIdentifier?: string;
  privateIp?: string;
  publicIp?: string;
  image: string;
  pulumiState: string;
  state: string;
  organizationId?: string;
  reservedInstanceId?: string;
  isStarted: boolean;
  isReady: boolean;
  isHealthy: boolean;
  debugModeEnabled: boolean;
  dateTimeCreated: string;
  firstTimeReadyDateTime?: string;
  expirationDateTime: string;
}

export type VirtualMachineList = PaginatedList<VirtualMachine>;

export type OrganizationCloudRenderingPolicies = InferType<
  typeof cloudRenderingPoliciesSchema
>;
