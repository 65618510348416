import axios from "axios";
import { CreateVirtualMachine } from "../cloud-rendering/VirtualMachines/virtualMachineSchema";
import { config } from "../config";
import { sessionSchema } from "./schema";
import {
  CloudProvider,
  FixedIp,
  OrganizationCloudRenderingPolicies,
  PaginatedList,
  Region,
  RegionFilter,
  ReservedInstance,
  ReservedInstanceType,
  Session,
  SessionFilters,
  SessionList,
  SessionStatsGroupByFilter,
  SessionsConcurrencyStat,
  SessionsStatDto,
  SessionsStatsDto,
  TimeZone,
  UnmanagedNode,
  UpdateReservedInstance,
  VirtualMachine,
  VirtualMachineList,
  VmSizeInformation,
  WeekDay,
} from "./types";

export type {
  CloudProvider,
  FixedIp,
  Region,
  RegionFilter,
  ReservedInstance,
  ReservedInstanceType,
  Session,
  SessionFilters,
  SessionList,
  SessionStatsGroupByFilter,
  SessionsConcurrencyStat,
  SessionsStatDto,
  SessionsStatsDto,
  TimeZone,
  UnmanagedNode,
  UpdateReservedInstance,
  VmSizeInformation
};

export interface VmFilter {
  page?: number;
  pageSize?: number;
  includeDestroyed?: boolean;
}

export { WeekDay };

export const axiosInstance = axios.create({
  baseURL: config.sessionManagementServiceEndpoint!,
});
export default axiosInstance;

export async function getSessionsPaginated({
  userIdentifier,
  ...filters
}: SessionFilters = {}): Promise<SessionList> {
  const data = (
    await axiosInstance.get<PaginatedList<Session>>("/v2/Sessions", {
      params: { userIdentifier: userIdentifier?.join(","), ...filters },
    })
  ).data;

  return { ...data, items: data.items.map((item) => sessionSchema.cast(item)) };
}

export async function getAllSessions(
  filters: SessionFilters,
): Promise<Session[]> {
  let allSessions: Session[] = [];
  let sessionsList: SessionList;
  let pageNumber: number = 1;

  do {
    sessionsList = await getSessionsPaginated({
      ...filters,
      page: pageNumber,
      pageSize: 200,
    });
    allSessions = allSessions.concat(sessionsList.items);
    pageNumber = sessionsList.pageNumber + 1;
  } while (sessionsList.hasNextPage);

  return allSessions;
}

export interface SessionsStatsFilters extends SessionFilters {
  groupBy?: SessionStatsGroupByFilter;
}

export async function getSessionsStats({
  userIdentifier,
  ...filters
}: SessionsStatsFilters): Promise<SessionsStatDto[]> {
  return (
    await axiosInstance.get<SessionsStatsDto>("/v2/Sessions/Stats", {
      params: { userIdentifier: userIdentifier?.join(","), ...filters },
    })
  ).data.sessionsStats;
}

export async function getSessionsConcurrencyStats({
  userIdentifier,
  ...filters
}: SessionFilters): Promise<SessionsConcurrencyStat> {
  return (
    await axiosInstance.get<SessionsConcurrencyStat>(
      "/v2/Sessions/Stats/Concurrency",
      {
        params: { userIdentifier: userIdentifier?.join(","), ...filters },
      },
    )
  ).data;
}

export async function getVmSizes() {
  return (
    await axiosInstance.get<{ vmSizes: VmSizeInformation[] }>(
      "/VirtualMachines/Sizes",
    )
  ).data.vmSizes;
}

export async function validatePortRequirements(ports: PortRule[]) {
  return await axiosInstance.post(
    "/VirtualMachines/ValidatePorts",
    {
      portRules: ports,
    },
    {
      validateStatus: (status) => {
        // status 400 means validation failed
        return status === 400 || status === 200;
      },
    },
  );
}

export async function getCloudRenderingRegions({
  organizationId,
  ...remainingFilters
}: RegionFilter): Promise<Region[]> {
  const response = await axiosInstance.post<{ regions: Region[] }>(
    "/Regions",
    {
      ...remainingFilters,
    },
    {
      headers: {
        "Portal-Organization-Id": organizationId,
      },
    },
  );

  return response.data.regions;
}

export async function updateCloudRenderingRegion(
  name: string,
  enabled: boolean,
  organizationId?: string | null,
) {
  await axiosInstance.patch(`/Regions/${name}`, {
    enabled: enabled,
  }, {
    headers: {
      "Portal-Organization-Id": organizationId,
    },
  });
}

export async function getReservedInstances() {
  return (
    await axiosInstance.get<{ reservedInstances: ReservedInstance[] }>(
      "/ReservedInstances",
    )
  ).data.reservedInstances;
}

export async function deleteReservedInstance(id: string) {
  await axiosInstance.delete(`/ReservedInstances/${id}`);
}

export async function updateReservedInstance(
  id: string,
  { organizationId, ...data }: UpdateReservedInstance,
  userIdentifier: string,
) {
  return (
    await axiosInstance.put<ReservedInstance>(
      `/ReservedInstances/${id}`,
      { ...data, userIdentifier },
      {
        headers: { "Portal-Organization-Id": organizationId },
      },
    )
  ).data;
}

export async function createReservedInstance(
  { organizationId, ...data }: UpdateReservedInstance,
  userIdentifier: string,
) {
  return (
    await axiosInstance.post<ReservedInstance>(
      "/ReservedInstances",
      { ...data, userIdentifier },
      {
        headers: { "Portal-Organization-Id": organizationId },
      },
    )
  ).data;
}

export async function getFixedIps(region?: string) {
  return (
    await axiosInstance.get<{ fixedIps: FixedIp[] }>(
      "/VirtualMachines/FixedIps",
      {
        params: { region },
      },
    )
  ).data.fixedIps;
}

export async function createFixedIps(count: number, region: string) {
  return (
    await axiosInstance.post<FixedIp>("/VirtualMachines/FixedIps", {
      count,
      region,
    })
  ).data;
}

export async function deleteFixedIps(fixedIpId: string) {
  return await axiosInstance.delete(`/VirtualMachines/FixedIps/${fixedIpId}`);
}

export async function getTimeZones() {
  return (await axiosInstance.get<{ timeZones: TimeZone[] }>("/TimeZones")).data
    .timeZones;
}

export function setBit(number: number | undefined | null, idx: number) {
  return (number ?? 0) | (1 << idx);
}

export function clearBit(number: number | undefined | null, idx: number) {
  return (number ?? 0) & ~(1 << idx);
}

export function toggleBit(number: number | undefined | null, idx: number) {
  return (number ?? 0) ^ (1 << idx);
}

export function isBit(number: number, idx: number) {
  return (number & (1 << idx)) !== 0;
}

let workWeekDaysBitMask = setBit(0, WeekDay.indexOf("Monday"));
workWeekDaysBitMask = setBit(workWeekDaysBitMask, WeekDay.indexOf("Tuesday"));
workWeekDaysBitMask = setBit(workWeekDaysBitMask, WeekDay.indexOf("Wednesday"));
workWeekDaysBitMask = setBit(workWeekDaysBitMask, WeekDay.indexOf("Thursday"));
workWeekDaysBitMask = setBit(workWeekDaysBitMask, WeekDay.indexOf("Friday"));
export const WorkWeekDaysBitMask = workWeekDaysBitMask;
let fullWeekDaysBitMask = setBit(
  workWeekDaysBitMask,
  WeekDay.indexOf("Saturday"),
);
fullWeekDaysBitMask = setBit(fullWeekDaysBitMask, WeekDay.indexOf("Sunday"));

export const FullWeekDaysBitMask = fullWeekDaysBitMask;

export type PortRule = {
  fromPort: number;
  toPort: number;
  direction: "Inbound" | "Outbound";
  ipProtocol: "Any" | "Tcp" | "Udp";
  cidrBlocks: string[];
  description: string;
};

export async function getDefaultPorts(): Promise<PortRule[]> {
  return (
    await axiosInstance.get<{ defaultPorts: PortRule[] }>(
      "/VirtualMachines/DefaultPorts",
    )
  ).data.defaultPorts;
}

export async function getUnmanagedNodesForRegion(region: string) {
  return (
    await axiosInstance.get<{ unmanagedNodes: UnmanagedNode[] }>(
      `/Regions/Unmanaged/${region}/UnmanagedNodes`,
    )
  ).data.unmanagedNodes;
}

export async function getVirtualMachines(
  params: VmFilter,
): Promise<VirtualMachineList> {
  const response = await axiosInstance.get<VirtualMachineList>(
    "/VirtualMachines",
    {
      params: params,
    },
  );

  return response.data;
}

export async function destroyVirtualMachine(id: string) {
  await axiosInstance.post(`/VirtualMachines/${id}/Destroy`);
}

export async function createVirtualMachine(
  vm: CreateVirtualMachine,
): Promise<VirtualMachine> {
  return (await axiosInstance.post("/VirtualMachines", vm)).data;
}

export async function enableVirtualMachineDebugMode(
  id: string,
): Promise<VirtualMachine> {
  return (await axiosInstance.post(`/VirtualMachines/${id}/EnableDebugMode`))
    .data;
}

export async function getOrganizationCloudRenderingPolicies() {
  return (
    await axiosInstance.get<OrganizationCloudRenderingPolicies>(
      `/Organizations/Settings`,
    )
  ).data;
}

export async function updateOrganizationCloudRenderingPolicies(
  policies: OrganizationCloudRenderingPolicies,
) {
  return (await axiosInstance.put("/Organizations/Settings", policies)).data;
}
